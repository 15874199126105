import { format } from "date-fns";
import RiskWrapper, {
  RiskColorWrapper,
} from "../../../components/overview/riskWrapper";
import { config } from "../../../Config/permission";
import InfoIcon from "@material-ui/icons/Info";

const getColumnName = (
  teamList,
  status,
  teamStatus,
  res,
  classes,
  setIsTrendOpen,
  setSelectedResourceDetail,
  isHalfSprintCompleted,
  sprintBarGraphData,
  history,
  state,
  definationPopupHandler
) => {
  const data = { ...teamList };
  data.columnsList = [
    {
      field: "Name",
      title: "Name",
      render: (rowData) =>
        status == "closed" ? (
          <div
            style={{ cursor: "pointer", color: "#5EBDCC", width: "200px" }}
            onClick={() => {
              history.push({
                pathname: `/project/${rowData?.ProjectId}/single-resource/${rowData?.results[0]?.Id}`,
                state: {
                  projectName: `Sprint`,
                  resourceName: rowData?.Name,
                  teamName: rowData?.results[0]?.TeamName,
                  startDate: format(
                    new Date(sprintBarGraphData?.startDate),
                    "yyyy-MM-d"
                  ),
                  endDate: format(
                    new Date(sprintBarGraphData?.endDate),
                    "yyyy-MM-d"
                  ),
                  date: {
                    startDate: new Date(sprintBarGraphData?.startDate),
                    endDate: new Date(sprintBarGraphData?.endDate),
                    key: "selection",
                    isCustom: true,
                    range: 14,
                  },
                },
              });
            }}
          >
            {rowData.Name}
          </div>
        ) : (
          rowData.Name
        ),
    },
    {
      field: "TeamName",
      title: "Team Name",
      // render: (rowData) => (
      //   <div >{rowData.TeamName ? rowData.TeamName : "-"}</div>
      // ),
      render: (rowData) => (
        <div
          className={classes.anchor}
          style={{ width: "150px" }}
          onClick={() =>
            history.push(
              `/project/${rowData?.ProjectId}/teams/resources?projectName=${state.ProjectName}`,
              {
                data: rowData?.TeamName,
                teamId: rowData.teamDetails[0]?.Id,
              }
            )
          }
        >
          <div style={{ cursor: "pointer", color: "#5EBDCC" }}>
            {rowData.TeamName ? rowData.TeamName : "-"}
          </div>
        </div>
      ),
    },
    {
      field: "AvgTaskCompleted",
      title: `Average capacity per Sprint (${config.type})`,
      render: (rowData) => (
        <div>{rowData.AvgTaskCompleted ? rowData.AvgTaskCompleted : "-"}</div>
      ),
    },
    {
      field: "taskAssigned",
      title: (
        <HeaderTitle
          lablel={`${config.type} Assigned`}
          definationPopupHandler={definationPopupHandler}
        />
      ),
      render: (rowData) => (
        <div>{rowData.taskAssigned ? rowData.taskAssigned.toFixed() : "-"}</div>
      ),
    },
    {
      title: (
        <HeaderTitle
          lablel={`${config.type} Completed (Overall)`}
          definationPopupHandler={definationPopupHandler}
        />
      ),
      field: "taskCompleted",
      render: (rowData) => (
        <div>
          {rowData.taskCompleted ? rowData.taskCompleted.toFixed() : "-"}
        </div>
      ),
    },
  ];
  if (status !== "closed") {
    data.columnsList.push({
      title: `${config.type}  completed Within Time`,
      field: "taskCompletedInTime",
      render: (rowData) => (
        <div>
          {rowData.taskCompletedInTime
            ? rowData.taskCompletedInTime.toFixed()
            : "-"}
        </div>
      ),
    });
  }
  if (status == "closed") {
    data.columnsList.push({ 
      title: (
        <HeaderTitle
          lablel={`${config.type} Spillover`}
          definationPopupHandler={definationPopupHandler}
        />
      ),
      field: "spSpillover",
    });
  }
  if (status !== "closed") {
    data.columnsList.push({
      title: `${config.type} Expected`,
      field: "workForcasted",
    });
  }

  data.columnsList.push(
    {
      title: (
        <HeaderTitle
          lablel={"Utilization Status"}
          definationPopupHandler={definationPopupHandler}
        />
      ),
      field: "Risk",
      render: (rowData) => (
        <>
          {rowData?.utilizationStatus === "-" ? (
            rowData?.utilizationStatus
          ) : (
            <RiskColorWrapper riskLevel={rowData?.utilizationStatus}>
              {rowData?.utilizationStatus === "Same"
                ? "unchanged"
                : rowData?.utilizationStatus}
            </RiskColorWrapper>
          )}
        </>
      ),
    },

    {
      title: `${config.type} Completed  (Trends)`,
      field: "storyPoint",
      render: (row) => (
        <button
          onClick={() => {
            setIsTrendOpen(true);
            setSelectedResourceDetail({
              ...row,
              type: "story",
              multiline: true,
            });
          }}
          className={classes}
          style={{ width: "150px" }}
        >
          View More
        </button>
      ),
    },
    {
      title: `${config.type} Spillover  (Trends)`,
      field: "spSpillover",
      width: "130px",
      render: (row) => (
        <button
          onClick={() => {
            setIsTrendOpen(true);
            setSelectedResourceDetail({ ...row, type: "Spillover" });
          }}
          className={classes}
          style={{ width: "150px" }}
        >
          View More
        </button>
      ),
    }
  );
  if (status !== "closed") {
    if (isHalfSprintCompleted) {
      data.columnsList.push({
        title: (
          <HeaderTitle
            lablel={"Performance Risk"}
            definationPopupHandler={definationPopupHandler}
          />
        ),
        field: "Risk",
        render: (rowData) => (
          <RiskWrapper>
            {teamStatus((rowData.taskCompleted / rowData.workForcasted) * 100)}
          </RiskWrapper>
        ),
      });
    } else {
      data.columnsList.push({
        title: (
          <HeaderTitle
            lablel={"Performance Risk"}
            definationPopupHandler={definationPopupHandler}
          />
        ),
        field: "Risk",
        render: (rowData) => <RiskWrapper>Low</RiskWrapper>,
      });
    }
  }
  // Base on Status column push
  if (status === "closed") {
    data.columnsList.push({
      title: (
        <HeaderTitle
          lablel={"Performance"}
          definationPopupHandler={definationPopupHandler}
        />
      ),
      field: "Risk",
      render: (rowData) => (
        <RiskColorWrapper riskLevel={rowData?.performanceStatus}>
          {rowData?.performanceStatus === "Same"
            ? "Unchanged"
            : rowData?.performanceStatus}
        </RiskColorWrapper>
      ),
    });
  }

  // Base on atisfy data column push
  if (config.sprint.sprintLevelIsSprintDeveloperInsidesPlanAndTimeSpent) {
    data.columnsList.push(
      { 
        title: (
          <HeaderTitle
            lablel={"Planned Time"}
            definationPopupHandler={definationPopupHandler}
          />
        ),
        field: "plannedTime",
        width: "300px",
        render: (row) => (
          <div style={{ width: "150px" }}>
            {row.plannedTime ? row.plannedTime : "-"}
          </div>
        ),
      },
      { 
        title: (
          <HeaderTitle
            lablel={"Time Spent"}
            definationPopupHandler={definationPopupHandler}
          />
        ),
        field: "timeSpent",
        width: "300px",
        render: (row) => (
          <div style={{ width: "150px" }}>
            {row.timeSpent ? row.timeSpent : "-"}
          </div>
        ),
      }
    );
  }
  if (config.sprint.sprintLevelIsSprintDevInsidesUilizationTrendLastSprint) {
    data.columnsList.push({
      title: "Utilization Trends,based on five sprint avg.",
      field: "utilizationStrength",
      render: (row) => (
        <button
          onClick={() => {
            setIsTrendOpen(true);
            setSelectedResourceDetail({ ...row, type: "utilization" });
          }}
          className={classes}
        >
          View More
        </button>
      ),
    });
  }
  if (config.sprint.sprintLevelIsSprintDevInsidesUilizationPercentLastSprint) {
    data.columnsList.push({
      title: "Utilization(%),based on five sprint avg.",
      field: "utilized",
      render: (row) => <>{row.utilized ? row.utilized : "-"}</>,
    });
  }
  data.data = res.data.filter((e) => e.Name);
  return data;
};
export { getColumnName };

export const HeaderTitle = ({ lablel, definationPopupHandler }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>{lablel} </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => definationPopupHandler(lablel)}
      >
        <InfoIcon style={{ color: "rgb(203 196 196)" }} fontSize="small" />
      </div>
    </div>
  );
};
